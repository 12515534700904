@import 'src/assets/scss/_variables.scss';


.highlight_hit {
    background-color: #ffde64;
  }
.data-manager-sidebar-icon {
    font-size: 20px;
    cursor: pointer;
  }
  .data-manager-right-part {
    margin-left: 257px !important;
  }
//   @media (max-width: 799.99px) {
//     .data-manager-right-part {
//         margin-left: 0 !important;
//       }
//   }


  
  table tr:hover td:first-child .diagonal-icon {
    display: block;
  }

// sidebar
.data-manager-sidebar {
    .sidebar {
        position: fixed;
        top: 63px;
        width: 257px;
        height: 100%;
        border-right: 1px solid #ccc;
        z-index: 10;
        transition: all 0.3s ease;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li a {
                display: block;
                padding: 10px;
                color: #555;
                text-decoration: none;
                transition: all 0.3s ease;

                & :hover {
                    background-color: #fff;
                    color: $primary-color;
                }
            }
        }
    }

    .sidebar.show {
        left: -16rem;
    }

    .delete-icon {
        font-size: 14px;
        cursor: pointer;
    }
}
//   icons

.table-action-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
    height: 30px;
    font-size: 16px;
    padding: 8px;
    color: #fff;
    background-color: $primary-color;
    transition: all 0.5s ease-in-out;

    i {
        transition: transform 0.5s ease-out;
    }

}

.data-manager-table {
    overflow: auto;
    max-height: calc(100vh - 192px);
    min-height: calc(100vh - 192px);
    -webkit-overflow-scrolling: touch;

    table thead {
        position: sticky;
        top: 0;
        background-color: $primary-color;
        color: #fff;
        z-index: 999;
    }

    table tbody tr {
        background-color: #fff;
    }

    table {
        margin: 0;
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        table-layout: fixed;
        border: 1px solid #f6f6f6;

        th,
        td {
            border: 1px solid #d8d8d8;
        }

        thead th {
            position: sticky;
            top: 0;
            z-index: 1;
            width: 200px;
        }

        td {
            background: #fff;
            padding: 0 0;
            text-align: center;

            input {
                border-radius: 1px;
                border-color: $primary-color;
            }
        }

        tbody {
            th {
                font-weight: 100;
                font-style: italic;
                text-align: left;
                position: relative;
            }
        }
    }

    table thead th:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
        background-color: $primary-color;
        color: #fff ;
    }

    table thead th:nth-child(2) {
        position: sticky;
        left: 200px;
        z-index: 2;
        background-color: $primary-color;
        color: #fff ;
    }

    table tbody th {
        position: sticky;
        left: 0;
        background: white;
        z-index: 1;
    }

    table {
        th:first-child {
            position: sticky;
            left: 0;
            z-index: 9;
            background: #1b3233;
        }

        th:nth-child(2) {
            position: sticky;
            left: 200px;
            z-index: 9;
            background: #1b3233;
        }

        td:nth-child(2) {
            position: sticky;
            left: 200px;
            background-color: #fff;
            z-index: 9;
        }

        td:first-child {
            position: sticky;
            left: 0;
            background-color: #fff;
            z-index: 9;
        }
    }

    .color-blue {
        background-color: $primary-color !important;
        color: #fff !important;
        z-index: 99999;
    }

    .diagonal-icon {
        display: none;
        position: absolute;
        top: 10px;
        right: 20px;
        cursor: pointer;
        transition: 0.3s ease-in-out;

        & :hover {
            transform: scale(1.3);
        }
    }
}
.page-preview {
    p {
        margin: 10px !important;
    }
    img {
        margin: 10px 0 !important;
    }
  }